<template>
	<div class="slot-edit-page" v-if="eventSlot">
		<SlotSettings v-if="!calendarIsDefault" 
			:eventSlot="eventSlot"
			:submitHandler="submitHandler" 
			:closeHandler="goToCalendar"/>
		<SlotShortSettings v-else 
			:eventSlot="eventSlot"
			:submitHandler="submitHandler"
			:closeHandler="goToCalendar"/>
	</div>
</template>

<script>
import router from '../router';
import YYYYMMDD, { convertTZ } from '../scripts/date';
import Slot from '../scripts/slot';
import CMS from '../service/cms/service';
import { isEqualDate } from '../scripts/date';
import { bus } from '../components/calendar/EventBus';

export default {
	name: 'SlotEdit',

	components: {
		SlotSettings: () => import("../components/calendar/table/SlotSettings.vue"),
		SlotShortSettings: () => import("../components/calendar/table/SlotShortSettings.vue"),
	},

	computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},
		calendarTimezone(){
			let timezone = this.$store.getters.getTimezone
            if (timezone && this.eventSlot == null) this.updateCurrentSlot()
			return this.$store.getters.getTimezone
		},
		calendarIsDefault(){
			let defaultUserCalendar = this.$store.getters.getDefaultCalendar
			let currentCalendar = this.$route.params.calendarUid
			return defaultUserCalendar == currentCalendar
		},
		browserTimezone(){
            let timezone = this.$store.getters.browserTimezone
            if (timezone && this.eventSlot == null) this.updateCurrentSlot()
            return timezone
		},
	},

	watch: {
        '$route.params.slotId': {
            handler: function () {
				this.eventSlotIsRequested = false
				let browserTzIsValid = this.browserTimezone && this.browserTimezone.id != -1
				let calendarTzIsValid = this.calendarTimezone && this.calendarTimezone.id != -1
				
				if (browserTzIsValid && calendarTzIsValid) this.updateCurrentSlot()
            },
            deep: true,
            immediate: true
        },
	},

	data() {
		return {
			eventSlot: null,	
			eventSlotIsRequested: false,
		};
	},
    beforeRouteLeave(to, from, next) {
        if (to.params.calendarUid == from.params.calendarUid && to.name == "calendar"){
            next()
        }
        else {
            let calendarUid = this.$route.params.calendarUid
            let calendarProps = this.$store.getters.calendarProperties
            router.replace({
                name:'calendar', 
                params:{calendarUid: calendarUid},
                query: calendarProps ? 
                    {
                        w:calendarProps.columnCount, 
                        dt:calendarProps.selectedDate
                    } : 
                    null
            })
			next()
        }
    },
	methods: {
		updateCurrentSlot(){
            let slotIndex = this.$route.params.slotId

			let slot = this.$store.getters.getSlotByIndex(slotIndex)
			if (slot) {
				this.eventSlot = slot
				return
			}

			if (this.eventSlotIsRequested) return
			this.eventSlotIsRequested = true
            CMS.slots.get(this.calendarUid, slotIndex).then((slotInfo) => {
                if (slotInfo.err) throw slotInfo.err
                let slotDetails = slotInfo.slot

                let date = new Date(slotDetails.startAt)
                let dateInCalendarTZ = convertTZ(date, this.browserTimezone.name, this.calendarTimezone.name)
                let dur = slotDetails.dur

                this.eventSlot = new Slot(dateInCalendarTZ, dur, slotDetails)
				this.eventSlot.addAttenders(slotInfo.attenders)
			})
        },
		goToCalendar(date){
			let calendarQuery = this.$store.getters.calendarProperties
			let dateYYYYMMDD
			if (date){
				dateYYYYMMDD = YYYYMMDD(date)
			} else {
				dateYYYYMMDD = calendarQuery.selectedDate
			}

			router.replace({
				name: "calendar",
				params: {
					calendarUid: this.calendarUid,
				},
				query: {
					dt: dateYYYYMMDD,
					w: calendarQuery.columnCount,
				}
			})
		},
		submitHandler(form){
			if (!form)
				return

            let browserTimezone = this.$store.getters.browserTimezone

            // 1. Basic validation
            let isValid = form.validate()
            if (!isValid) return 

            // 2. Date validation with difference between timezones
            isValid = form.validateConvertedDate(browserTimezone)
            if (!isValid) return

            // 3. Get form data to submit
            let submitData = form.toSubmitData()
            let calendarUid = this.$route.params.calendarUid

            CMS.slots.update(calendarUid, this.eventSlot.index, submitData)
            .then( () => {
                let formDate = form.calendarDate ?? form.date
                let date = new Date(formDate.getTime())
                let slotStart = this.eventSlot.period.start.date
                let dateIsChanged = !isEqualDate(date, slotStart)

                // If date is changed -> change card column
                if (dateIsChanged){
                    bus.$emit('table', 'update-slot-date', {
                        date: date,
                        index: this.eventSlot.index,
                        isAfterPut: true,
                    })
                }

                // Update card view
                bus.$emit(`new-card-${this.eventSlot.index}`, `update-card-info`, {
                    date: date,
                    title: form.title,
                    maxAttenders: form.maxAttenders,
                    mainColor: form.cellBackgroundColor,
                    textColor: form.cellTextColor,
                    isAfterPut: true,
                })
                
                // Update slot data
                submitData.startAt = new Date(date.getTime())
                submitData.dur = form.duration
                this.eventSlot.updateSettings(submitData)

                if (form.isCreateTemplate){
                    this.$store.dispatch('setup-slot-templates')
                }
                this.goToCalendar(this.eventSlot.period.start.date)
            })
		}
	},
};
</script>

<style scoped>
.slot-edit-page{
	height: 100%;
}
</style>